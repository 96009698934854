
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import JwtService from "@/core/services/JwtService";
import { Actions } from "@/store/enums/StoreEnums";
import { ActionsFi } from "@/store/enums/StoreFiEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  props: {
    id: { required: true },
  },

  name: "apps-create-purchase-rquisition-details",
  components: {},

  setup(props) {
    const formRef = ref<null | HTMLFormElement>(null);
    const myData = ref<MyDataStructure[]>([]);
    const tableData = ref<TableData[]>([]);
    const loading = ref<boolean>(false);
    const prDetails = ref<Array<any>>([]);
    const newProductArray = ref<Array<any>>([]);
    const oldPrRemark = ref<string>("")

    const prData = ref({}) as any;
    const isEditModeOn = ref<boolean>(false);
    const dialogSelectProductTableVisible = ref(false);

    // add product form
    const manufacturer_data = ref([]);
    const standardJson = ref<any>([]);
    const structure_data = ref<any>([]);

    const cat_attr_count = ref(0);
    const categoryTree = ref([]);
    const treeProps = ref({
      children: "sub_attributes",
      label: "category_name",
    });
    const treeRef = ref<null | HTMLFormElement>(null);
    const formAddProductRef = ref<null | HTMLFormElement>(null);
    const newTreeValue = ref<any>([]);
    const newTreeNode = ref<any>({});
    const table_data = ref<any>([]);
    const standard_data = ref([]);
    const my_data_id = ref(0);
    const category_data = ref<Array<any>>([]);

    const router = useRouter();
    const store = useStore();

    getPurchaseRequisitonDetails();
    getCategoryTree();

    interface Category {
      category_id: string;
      category_name: string;
      is_next_child_present: number;
    }

    interface TableData {
      pr_detail_id: number | null;
      id: number;
      product_name: "";
      required_qty: number;
      remarks: "";
      mfg_company_json: Record<string, string | number>[];
      description: Record<string, string>[];
      edit_flag: number;
      delete_flag: number;
      is_new: boolean;
      uom: "";
    }

    interface MyDataStructure {
      id: number;
      pr_detail_id: number;
      mfg_company_json: Record<string, string | number>[];
      category_id: string;
      brand_ids: Record<string, string | number>[];
      required_qty: string;
      uom: Record<string, unknown>;
      remarks: string;
      specifications: Record<string, string | number>[];
      product_name: string;
      standard_id: number;
      standard_attribute_id: any;
      category_attribute_json: Record<string, string | number>[];
      product_descriptions: Record<any, any>;
    }

    const formData = ref({
      uom_select: "",
      sjvd_date: "",
      pr_remarks: "",
    });

    const rules = ref({});

    async function getPurchaseRequisitonDetails() {
      let params = { pr_id: props.id };

      store
        .dispatch(ActionsFi.CUST_GET_PURCHASE_REQUISITION_DETAILS, params)
        .then(({ data }) => {
          prData.value = data;
          formData.value.pr_remarks = prData?.value?.pr_remarks;
          tableData.value = [];
          let pr_detail = data?.pr_detail;
          oldPrRemark.value = data?.pr_remarks
          if (pr_detail?.length) {
            pr_detail.map((item, index) => {
              let pr_detail_data: any = {};
              pr_detail_data["pr_detail_id"] = item?.pr_detail_id;
              pr_detail_data["product_name"] = item?.category_name;
              pr_detail_data["required_qty"] = item?.pr_quantity;
              pr_detail_data["remarks"] = item?.pr_remark;
              pr_detail_data["mfg_company_json"] = item?.mfg_company_json;
              pr_detail_data["description"] = item?.description;
              pr_detail_data["edit_flag"] = item?.edit_flag;
              pr_detail_data["delete_flag"] = item?.delete_flag;
              pr_detail_data["is_new"] = false;
              pr_detail_data["uom"] = item?.uom;
              tableData.value.push(pr_detail_data);
            });
          }
          if (myData?.value?.length) {
            addNewProduct(myData);
          }
        })
        .catch((response) => {
          console.log(response);
        });
    }

    rules.value = {
      // sjvd_date: [
      //   {
      //     required: true,
      //     message: "Requsition Date is required",
      //     trigger: "change",
      //   },
      // ],
    };

    const setFormEditMode = () => {
      isEditModeOn.value = !isEditModeOn.value;
      if (!isEditModeOn.value) {
        tableData.value.map((item, index) => {
          item["edited"] = false;
        });
      }
    };

    const editRow = (row) => {
      row.edited = true;
    };

    const saveEditedRow = (row) => {
      row.edited = false;
      let prDetailsObj = {};
      if (!row?.is_new && row?.pr_detail_id) {
        if (prDetails?.value.length) {
          let index = prDetails.value.findIndex((object) => {
            return object.pr_detail_id === row.pr_detail_id;
          });
          if (index !== -1) {
            prDetails.value[index].pr_quantity = row?.required_qty;
            prDetails.value[index].pr_remark = row?.remarks;
            return;
          }
        }
        prDetailsObj["pr_detail_id"] = row?.pr_detail_id;
        prDetailsObj["pr_quantity"] = row?.required_qty;
        prDetailsObj["pr_remark"] = row?.remarks;
        prDetails.value.push(prDetailsObj);
      }
    };

    const deleteRow = async (row) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          if (row.is_new) {
            var myDataIndex = myData.value
              .map((deteRow) => {
                return deteRow.id;
              })
              .indexOf(row?.id);
            var tableIndex = tableData.value
              .map((deteRow) => {
                return deteRow.id;
              })
              .indexOf(row?.id);
            myData.value.splice(myDataIndex, 1);
            tableData.value.splice(tableIndex, 1);
            if (myData.value.length === 0) {
              my_data_id.value = 0;
            }
            Swal.fire({
              text: "Product deleted successfully!",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          } else {
            deleteProduct(row);
          }
        } else {
          Swal.fire("Safe!", "Your data is safe.", "success");
        }
      });
    };

    const deleteProduct = async (row) => {
      let params = { pr_detail_id: row?.pr_detail_id };
      await store
        .dispatch(ActionsFi.CUST_DELETE_PURCHASE_REQUISITION_PRODUCT, params)
        .then(({ data }) => {
          if (data && data.is_deleted) {
            if (prDetails?.value.length) {
              let index = prDetails.value.findIndex((object) => {
                return object.pr_detail_id === row.pr_detail_id;
              });
              if (index !== -1) {
                prDetails.value.splice(index, 1);
              }
            }
            setTimeout(() => {
              Swal.fire({
                text: "Product deleted successfully!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                getPurchaseRequisitonDetails();
              });
            }, 2000);
          }
        })
        .catch((response) => {
          console.log(response);
        });
    };

    //add product part start
    const openDilogue = () => {
      dialogSelectProductTableVisible.value = true;
    };

    const formProductData = ref({
      manufacturers_select: [],
      category_select: "",
      category_name: "",
      qty: "",
      remark: "",
      uom_select: {},
      attribute_name: [],
      standard_select: {},
      standard_attr_select: {},
    }) as any;

    async function getCategoryTree() {
      await store
        .dispatch(Actions.CUST_GET_CATEGORY_TREE)
        .then(({ data }) => {
          debugger;
          categoryTree.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    interface Tree {
      category_name: string;
      category_id: string;
      uom_name: string;
      uom_id: number;
    }
    const handleCheckChange = (
      data: Tree,
      checked: boolean,
      indeterminate: boolean
    ) => {

      resetProductForm(true);
      formAddProductRef?.value?.resetFields();
      formAddProductRef?.value?.clearValidate()
      formProductData.value["standard_select"] = "";
      formProductData.value['standard_attr_select']="";
      manufacturer_data.value = [];
      formProductData.value["category_select"] = data.category_id;
      formProductData.value["category_name"] = data.category_name;
        formProductData.value["uom_select"] = {
          uom_id: data.uom_id,
          uom_name: data.uom_name,
      }
      getCompanyList(), getStandardGradeJson(), getAttributeStructure();

    };

    const getCompanyList = async () => {
      let values = {
        category_id: formProductData?.value?.category_select,
        page: 1,
        records_per_page: 1000,
      };
      await store
        .dispatch(Actions.CUST_GET_CATEGORY_MANUFACTURER_LIST_DATA, values)
        .then(({ data }) => {
          debugger;
          manufacturer_data.value = data.result_list;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    const getStandardGradeJson = async () => {
      debugger;
      var values = {
        category_id: formProductData.value["category_select"],
        company_id: 0,
        company_branch_id: 0,
        brand_id: 0,
      };
      loading.value = true;
      await store
        .dispatch(Actions.CUST_GET_PRODUCT_STANDARD_JSON_DATA, values)
        .then((data) => {
          debugger;
          if (data.status === 200) standardJson.value = data.data;
          else standardJson.value = [];
          loading.value = false;
        })
        .catch(({ e }) => {
          console.log(e);
        });
    };

    async function getAttributeStructure() {
      debugger;

      var values = {
        category_id: formProductData.value["category_select"],
        company_id: 0,
        company_branch_id: 0,
        brand_id: 0,
      };
      loading.value = true;
      cat_attr_count.value = 0;
      await store
        .dispatch(
          Actions.CUST_GET_PRODUCT_ATTRIBUTE_STRUCTURE_BY_CATEGORY_ID,
          values
        )
        .then(({ data }) => {
          debugger;
          loading.value = false;

          let formStructure = data.map((item) => {
            if (item.input_value_required_yn === 0) {
              let value = {};
              value["parentLabel"] = item.attribute_label;
              value["input_value_required_yn"] = item.input_value_required_yn;
              let childAttr = item.sub_attributes.map((child) => {
                let subAttr = {
                  input_value_type: child.input_value_type,
                  attribute_label: child.attribute_label,
                  category_attribute_setting_id:
                    child.category_attribute_setting_id,
                  category_attribute_value: "",
                  min_value: child.min_value,
                  max_value: child.max_value,
                  input_value_required_yn: child.input_value_required_yn,
                  attribute_values: child.attribute_values,
                  attribute_name: child.attribute_label.toLowerCase(),
                };
                if (child.input_value_type === "RANGE") {
                  subAttr["range"] = {
                    min: parseFloat(child.min_value),
                    max: parseFloat(child.max_value),
                  };
                }
                cat_attr_count.value++;
                return subAttr;
              });
              value["subAttr"] = childAttr;
              return value;
            } else {
              let value = {
                input_value_type: item.input_value_type,
                attribute_label: item.attribute_label,
                category_attribute_setting_id:
                  item.category_attribute_setting_id,
                category_attribute_value: "",
                min_value: 0,
                max_value: 0,
                input_value_required_yn: item.input_value_required_yn,
                attribute_values: item.attribute_values,
                attribute_name: item.attribute_label.toLowerCase(),
              };
              value["parentLabel"] = "";
              if (item.input_value_type === "RANGE") {
                // value["range"] = {
                  value.min_value = parseFloat(item.min_value);
                  value.max_value = parseFloat(item.max_value);
                // };
              }
              cat_attr_count.value++;
              return value;
            }
          });
          structure_data.value = formStructure;
          let rule_stuctrue = {};
          formStructure.map((item) => {
            if (item.subAttr) {
              item.subAttr.map((attr) => {
                let label = attr.attribute_name;
                rule_stuctrue[label] = [
                  {
                    required: true,
                    message: attr.attribute_label + "  is required",
                    trigger: "change",
                  },
                ];
              });
            } else {
              let label = item.attribute_name;
              rule_stuctrue[label] = [
                {
                  required: true,
                  message: item.attribute_label + "  is required",
                  trigger: "change",
                },
              ];
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const rangeValidator = async (
      value,
      rowIndex,
      childIndex,
      min,
      max,
      fileld
    ) => {
      debugger;
      let val = parseFloat(value);
      let minNum = parseFloat(min);
      let maxNum = parseFloat(max);
      let input: any = document.getElementsByClassName(fileld)[0].children[0];
      let warning: any = document.getElementById(fileld);
      warning.innerHTML = "";
      input.style.borderColor = "";
      if ((!/^\d*\.?\d*$/.test(value))||
          (value < minNum || val > maxNum || Number.isNaN(val))) {
        formProductData.value.attribute_name[fileld] = "";
        warning.innerHTML = "Please enter value between " + min + "-" + max;
        input.style.borderColor = "red";
      }
    };

    const productRules = ref({
      category_select: [
        {
          required: true,
          message: "Category is required",
          trigger: "change",
        },
      ],
      // manufacturers_select: [
      //   {
      //     required: true,
      //     message: "Manufacturer is required",
      //     trigger: "change",
      //   },
      // ],
      standard_select: [
        {
          required: true,
          type: "object",
          message: "Standard is required",
          trigger: "change",
        },
      ],
      standard_attr_select: [
        {
          required: true,
          message: "Grade is required",
          trigger: "change",
        },
      ],
      qty: [
        {
          required: true,
          message: "Quantity is required",
          trigger: "change",
        },
      ],
    });

    const submitProduct = async () => {
      loading.value = true;
      if (!formAddProductRef.value) {
        loading.value = false;
        return;
      }

      formAddProductRef.value.validate(async (valid) => {
        if (valid) {
          addProduct();
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    const addProduct = () => {
      debugger;
      let spec: any = [];
      let product_description = {} as any;

      let mfg_company_json = formProductData.value.manufacturers_select.map(
        (item: any) => {
          return {
            company_id: item.company_id,
            company_name: item.manufacturer_name,
          };
        }
      );

      let category_attributes = formProductData.value.attribute_name;
      let selected_attr_count = Object.keys(category_attributes).length;
      let attribute_emapty = false;
      for (let key in category_attributes) {
        let value: any = category_attributes[key];
        if (typeof value === "string" && !value.trim()) {
          attribute_emapty = true;
          break;
        }
      }

      if (selected_attr_count < cat_attr_count.value || attribute_emapty) {
        Swal.fire({
          text: "Plese fill all the category attributes !",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        loading.value = false;
        return;
      }

      let category_attributes_array = [] as any;
      for (let key in category_attributes) {
        let keys = key.split(",");
        let category_attribute_setting_id = parseInt(keys[1]);
        let attribute_value: any = category_attributes[key];
        let new_attribute_obj = {};
        if (
          typeof attribute_value === "object" &&
          Object.keys(attribute_value).includes("category_attribute_generic_id")
        ) {
          new_attribute_obj = Object.assign(
            { category_attribute_setting_id: category_attribute_setting_id },
            attribute_value
          );
          category_attributes_array.push(new_attribute_obj);
          let keyName =
            keys[0].toString().charAt(0).toUpperCase() +
            keys[0].toString().slice(1);
          product_description[keyName] =
            attribute_value.category_attribute_value;
        } else {
          new_attribute_obj["category_attribute_setting_id"] =
            category_attribute_setting_id;
          new_attribute_obj["category_attribute_generic_id"] = 0;
          new_attribute_obj["category_attribute_value"] = attribute_value;
          category_attributes_array.push(new_attribute_obj);
          for (let item of structure_data.value) {
            if (item["parentLabel"] && Object.keys(item["subAttr"].length)) {
              product_description[item["parentLabel"]] =
                product_description[item["parentLabel"]] &&
                Object.keys(product_description[item["parentLabel"]]).length !==
                  0
                  ? Object.assign({}, product_description[item["parentLabel"]])
                  : {};
              for (let data of item["subAttr"]) {
                if (
                  data["category_attribute_setting_id"] ===
                  category_attribute_setting_id
                ) {
                  product_description[item["parentLabel"]][
                    data["attribute_label"]
                  ] = attribute_value;
                }
              }
            }else if(item['parentLabel'] == "" && 
            item['category_attribute_setting_id'] == category_attribute_setting_id){
              product_description[item["attribute_name"]] = attribute_value;
            }
          }
        }
      }
      if (formProductData.value.standard_select["standard_no"]) {
        product_description["standard_name"] = formProductData.value
          .standard_select["standard_no"]
          ? formProductData.value.standard_select["standard_no"]
          : "";
      }
      if (
        formProductData.value.standard_attr_select["standard_attribute_value"]
      ) {
        product_description["grade"] = formProductData.value
          .standard_attr_select["standard_attribute_value"]
          ? formProductData.value.standard_attr_select[
              "standard_attribute_value"
            ]
          : "";
      }
      my_data_id.value++;
      let newAddedProductDetails = {
                  id: my_data_id.value,
        pr_detail_id: 0,
        category_id: formProductData.value["sub_category_select"]
          ? formProductData.value["sub_category_select"]
          : formProductData.value["category_select"],
        brand_ids: formProductData.value["brand_select"],
        required_qty: formProductData.value["qty"],
        uom: formProductData.value["uom_select"],
        remarks: formProductData.value["remark"],
        specifications: spec,
        product_name: formProductData.value["category_name"],
        mfg_company_json: mfg_company_json,
        standard_id: formProductData.value.standard_select["standard_id"]
          ? formProductData.value.standard_select["standard_id"]
          : 0,
        standard_attribute_id: formProductData.value.standard_attr_select[
          "standard_attribute_generic_id"
        ]
          ? formProductData.value.standard_attr_select[
              "standard_attribute_generic_id"
            ]
          : 0,
        category_attribute_json: category_attributes_array,
        product_descriptions: product_description,
      }
      myData.value.push(newAddedProductDetails)
      loading.value = false;
      newProductArray.value = [];
      newProductArray.value.push(newAddedProductDetails)
      addNewProduct(newProductArray);
      resetProductForm();
    };

    const addNewProduct = (productData) => {
      productData.value.map((item, index) => {
        let newAddedProduct = {} as any;
        newAddedProduct["id"] = item.id;
        newAddedProduct["pr_detail_id"] = item?.pr_detail_id;
        newAddedProduct["product_name"] = item?.product_name;
        newAddedProduct["required_qty"] = item?.required_qty;
        newAddedProduct["remarks"] = item?.remarks;
        newAddedProduct["mfg_company_json"] = item?.mfg_company_json;
        newAddedProduct["description"] = item?.product_descriptions;
        newAddedProduct["is_new"] = true;
        newAddedProduct["uom"] = item?.uom.uom_name;

        tableData.value.push(newAddedProduct);
      });
    };

    const resetProductForm = async (isCategoryChange = false) => {
      formProductData.value.manufacturers_select = [];
      formProductData.value.category_select = "";
      formProductData.value.category_name = "";
      formProductData.value.qty = "";
      formProductData.value.remark = "";
      formProductData.value.uom_select = {};
      formProductData.value.attribute_name = [];
      formProductData.value.standard_select = "";
      formProductData.value.standard_attr_select = "";
      manufacturer_data.value = [];
      standardJson.value = [];
      structure_data.value = [];
      table_data.value = [];
      standard_data.value = [];
      if(!isCategoryChange){
        dialogSelectProductTableVisible.value = false;
      }
    };

    // add product part end
    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await updatePurchaseRequisition();
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    const updatePurchaseRequisition = async () => {
      let user = JSON.parse(JwtService.getToken());

      let product_list = myData.value.map((item) => {
        return {
          pr_detail_id:item.pr_detail_id,
          category_id: item.category_id,
          mfg_company_json: item.mfg_company_json,
          standard_id: item.standard_id,
          standard_attribute_generic_id: item.standard_attribute_id,
          category_attribute_json: item.category_attribute_json,
          pr_quantity: item.required_qty,
          uom_json: item.uom,
          pr_remark: item.remarks,
        };
      });

      let prDetailsArray = product_list.concat(prDetails.value);

      if (tableData.value.length <= 0 || (prDetailsArray.length <=0 && oldPrRemark.value === formData?.value?.pr_remarks)) {
        console.log("validation failed");
        Swal.fire({
          text: tableData.value.length <= 0 ? "Please Add At Least One Product" : "No changes detected",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        loading.value = false;
        return;
      }

      let params = {
        pr_id: prData?.value.pr_id,
        pr_remarks: formData?.value?.pr_remarks,
        pr_detail: prDetailsArray,
        own_user_id: user.user_id,
        user_id: user.user_id,
      };
      store
        .dispatch(ActionsFi.CUST_UPDATE_PURCHASE_REQUISITION_DETAILS, params)
        .then(({ data }) => {
          loading.value = false;
          if (data?.is_updated) {
            prDetails.value = [];
            myData.value = [];
            Swal.fire({
              text: "Updated successfully !",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            getPurchaseRequisitonDetails();
          }
        })
        .catch((response) => {
          console.log(response);
        });
    };

    async function getCategoryData(id, type) {
      await store
        .dispatch(Actions.CUST_GET_CATEGORY_PARENT_LIST)
        .then(({ data }) => {
          debugger;
          category_data.value = data ? data : [];
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const validateInput = (fieldName) => {
      if (!/^\d*\.?\d*$/.test(formProductData.value[fieldName]) ||
          formProductData.value[fieldName] == 0
        ){
        formProductData.value[fieldName] = formProductData.value[fieldName].slice(0, -1);
        return
      }
    }

    onMounted(async () => {
      setCurrentPageBreadcrumbs("Purchase Requisition Details", []);
      getCategoryData(0, '');
    });

    return {
      formData,
      formRef,
      rules,
      myData,
      tableData,
      prData,
      isEditModeOn,
      submit,
      setFormEditMode,
      dialogSelectProductTableVisible,
      openDilogue,
      saveEditedRow,
      editRow,
      deleteRow,
      loading,
      //Add product form variables
      standardJson,
      structure_data,
      formProductData,
      categoryTree,
      formAddProductRef,
      treeProps,
      treeRef,
      handleCheckChange,
      manufacturer_data,
      productRules,
      submitProduct,
      resetProductForm,
      rangeValidator,
      category_data,
      validateInput
    };
  },
});
